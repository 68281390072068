// Items
export const GET_ITEMS = 'GET_ITEMS';
export const CLEAR_ITEMS = 'CLEAR_ITEMS';
export const ADD_ITEM = 'ADD_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export const SET_CURRENT = 'SET_CURRENT';
export const CLEAR_CURRENT = 'CLEAR_CURRENT';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const FILTER_ITEMS = 'FILTER_ITEMS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Auth
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const ITEM_ERROR = 'CONTACT_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
